define("mldp/account/admin/route", ["exports", "@ember/routing/route", "@ember/service"], function (_exports, _route, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let AccountAdminRoute = (_dec = (0, _service.inject)('role-service'), (_class = class AccountAdminRoute extends _route.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "session", _descriptor, this);

      _initializerDefineProperty(this, "state", _descriptor2, this);

      _initializerDefineProperty(this, "roleService", _descriptor3, this);
    }

    beforeModel() {
      if (!this.roleService.isAdmin) {
        this.transitionTo('account');
      }
    }

    async model() {
      const feeTransationAdapter = this.store.adapterFor('feetransaction');
      const processAdapter = this.store.adapterFor('process');
      const staticTableAdapter = this.store.adapterFor('mldp-static-table');
      const refundMethods = await feeTransationAdapter.getRefundMethods();
      const currentUser = await this.store.findRecord('user', this.session.userId);
      const processTypes = await processAdapter.getProcessTypes();
      let springReportingYear = await staticTableAdapter.getCurrentSeasonYear('spring');
      let fallReportingYear = await staticTableAdapter.getCurrentSeasonYear('fall');
      springReportingYear = this.state.seasons.find(x => x.identifier === springReportingYear.season);
      fallReportingYear = this.state.seasons.find(x => x.identifier === fallReportingYear.season);
      return {
        refundMethods,
        currentUser,
        processTypes,
        springReportingYear,
        fallReportingYear
      };
    }

    setupController(controller, model) {
      super.setupController(controller, model);
      controller.set('refundMethods', model.refundMethods);
      controller.set('currentUser', model.currentUser);
      controller.set('processTypes', model.processTypes);
      controller.set('fallReportingYear', model.fallReportingYear);
      controller.set('springReportingYear', model.springReportingYear);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "session", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "state", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "roleService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = AccountAdminRoute;
});